import get from 'lodash/get';
import isNil from 'lodash/isNil';
import dom from '../../../wrapper/DomWrapper';

import { getDeviceType } from '../../../helpers/browser';
import defaultImage from '../utils';
import errorHandler from './errorHandler';

const photoErrorHandler = () => {
  const selectorName = '.picture-wrap';
  const onError = (elImage, elParent) => {
    const deviceType = getDeviceType();
    const source = dom.getElement(`source[data-device=${deviceType}]`, elParent);
    const previous = isNil(source) ? get(elImage, ['previousSibling', 'previousSibling']) : source;

    if (!previous) return;

    previous.srcset = defaultImage;
    dom.addClass(elParent, 'picture-wrap_unavailable');
  };

  errorHandler(selectorName, onError);
};

export default photoErrorHandler;
